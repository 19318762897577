import * as React from 'react';
import { Link } from 'gatsby';

import {
  BackToTopButton,
  Title,
  BlogImage,
  BlogTitle,
  BlogSubtitle,
  BlogControls,
  BlogCredits,
} from '../../../components';

import { Page, BlogArticles } from '../../../fragments';

import routes from '../../../routes';

import projektBezTytulu2 from '../../../images/pl/Projekt_bez_tytułu.png';
import projektBezTytulu1 from '../../../images/pl/Projekt_bez_tytułu.jpg';
import projektBezTytulu3 from '../../../images/pl/kid.jpg';

const PrzedszkolakILogopedia = () => {
  return (
    <Page
      title="Przedszkolak i logopedia"
      description="Kokolingo jest aplikacją logopedyczną, która przenosi dziecko w
      magiczny świat głosek,gwarantując mnóstwo zabawy oraz, co
      najważniejsze, czas spędzony z dzieckiem podczas nauki ma wymiar
      rodzinny. Co więcej, ćwicząc poprawną wymowę, która ma swoje
      przełożenie na rożneaspekty komunikacyjne dajemy dziecku i
      rodzicowi poczucie dobrze spędzonego ze sobą czasu."
    >
      <div className="educational-corner-page">
        <Title
          variant="dark"
          className="educational-corner-page__title no-margin"
        >
          KOKOLINGO KĄCIK EDUKACYJNY
        </Title>
        <div className="educational-corner__container">
          <div className="educational-corner__blog blog">
            <div>
              <BlogTitle>Przedszkolak i logopedia</BlogTitle>
              <p>
                Dlaczego warto pracować z dzieckiem w domu używając aplikacji
                Kokolingo? I co na to metodyka pracy logopedycznej z dzieckiem w
                wieku przedszkolnym i wczesnoszkolnym?
              </p>
              <p>
                Kokolingo jest aplikacją logopedyczną, która przenosi dziecko w
                magiczny świat głosek, gwarantując mnóstwo zabawy oraz, co
                najważniejsze, czas spędzony z dzieckiem podczas nauki ma wymiar
                rodzinny. Co więcej, ćwicząc poprawną wymowę, która ma swoje
                przełożenie na rożne aspekty komunikacyjne dajemy dziecku i
                rodzicowi poczucie dobrze spędzonego ze sobą czasu. Kokolingo
                daje nieograniczone możliwości gier w które tak naprawdę może
                grać nawet cała rodzina! Daje to dziecku ogromne poczucie
                wsparcia, poczucie, że towarzyszymy mu w codziennej pracy nad
                prawidłową artykulacją. Praca z głoskami staje się ciekawa, a
                czas spędzony z papugą Koko i Piratem Kazikiem jest bezcenny.
                Kto nie chciałby mieć takich przyjaciół? Pamiętajmy, że Papuga
                Koko i Pirat Kazik to prawdziwi przyjaciele, którzy zostają z
                nami przez całą przygodę i przemierzają z nami labirynt dźwięków
                mowy. By owocnie pracować z dzieckiem, my dorośli, musimy
                pamiętać o podstawowych, metodycznych zasadach.
              </p>
              <p>
                Podczas pracy z dzieckiem dorosłych obowiązuje kilka bardzo
                ważnych zasad
              </p>
              <BlogImage
                imgSrc={projektBezTytulu1}
                imgAlt="Predskolsko dijete i logopedia 1"
                imgTitle="Predskolsko dijete i logopedia 1"
              />
              <BlogSubtitle>Unikamy metajęzyka</BlogSubtitle>
              <p>
                Dorośli mają tendencję do mówienia o tym, co dla nich jest
                jasne. Dziecko jednak nie zawsze zrozumie, o co nam chodzi. Aby
                upewnić się, że dziecko zrozumiało nasz komunikat wystarczy
                poprosić je, aby powtórzyło to co ma zrobić własnymi słowami.
                Dzięki temu uzyskamy pewność, że nasz komunikat został poprawnie
                zrozumiany.
              </p>
              <p>
                I my słuchamy uważnie logopedów, kiedy tłumaczą i pokazują jak
                tworzy się dana głoska – razem z dzieckiem słuchajmy wskazówek
                logopedy, używajmy zalecanych rekwizytów, takich jak lusterko,
                aby dokładnie i w zrozumiały dla dziecka sposób pokazywać jak
                realizuje się prawidłowo daną głoskę.
              </p>
              <BlogSubtitle>Unikamy pojęć abstrakcyjnych</BlogSubtitle>
              <p>
                Dorośli często odwołują się do zjawisk, które są im znane. Mówią
                na przykład: „nie jedz soli, bo jest słona”. Dla dziecka
                stwierdzenie „sól jest słona” nie znaczy nic. Żeby zrozumieć
                pojęcie, potrzebuje ono konkretu, czegoś namacalnego, czego może
                doświadczyć. Im więcej prób związanych z doświadczeniem, tym
                bardziej materiał staje się zrozumiały, co więcej każde
                doświadczenie przyczynia się do kształtowania i rozbudzania
                wyobraźni u dziecka.
              </p>
              <p>
                Dziecko nie wie co to jest aparat artykulacyjny, narządy mowy,
                apeks języka, ale...może samo poczuć wibracje podczas wymawiania
                głosek dźwięcznych, może doświadczyć pracy przepony podczas
                ćwiczeń prawidłowego toru oddechowego – pozwólmy dzieciom na
                eksperymenty i zdobywania doświadczeń płynących z ciała – to w
                terapii logopedycznej niezwykle istotne!
              </p>
              <BlogImage
                imgSrc={projektBezTytulu2}
                imgAlt="Predskolsko dijete i logopedia 2"
                imgTitle="Predskolsko dijete i logopedia 2"
              />
              <BlogSubtitle>Nic na siłę. Niczego nie narzucamy</BlogSubtitle>
              <p>
                Rodzice często wyznaczają dziecku dogodny dla siebie czas, do
                którego musi się ono dostosować. Pamiętajmy, że dziecko – tak
                jak my – też może być zmęczone albo mieć gorszy dzień. Należy
                zapytać dziecko o samopoczucie, zaprosić do ćwiczeń,podziękować
                za pracę. Pozwólmy dziecku wybrać ćwiczenie (w Kokolingo ten
                wybór jest bardzo szeroki! Wykorzystajmy to!). Dziecko powinno
                mieć wpływ na wybór zadania, dzięki temu staje się czynnym
                uczestnikiem i ma namacalny wpływ na swoją naukę poprzez zabawę.
              </p>
              <BlogSubtitle>Dopuszczamy zmianę planu</BlogSubtitle>
              <p>
                Dziecko nie zawsze ma ochotę na to samo, co dorosły. Zdarza się,
                że nasz pomysł na zajęcia z dzieckiem nie trafia w
                zainteresowania dziecka. Zmuszanie do wykonania ćwiczenia, które
                przygotowaliśmy często przynosi efekt odwrotny. Pracując z
                dzieckiem w wieku przedszkolnym i wczesnoszkolnym zawsze trzeba
                być otwartym na zmianę ćwiczenia. Dlatego tak ważnym jest
                posiadanie kilku ćwiczeń dostosowanych do zainteresowań
                przedszkolaka, które zawsze znajdują aprobatę u dziecka.
              </p>
              <BlogSubtitle>
                Pamiętamy o metodach aktywizujących i różnorodnych formach pracy
              </BlogSubtitle>
              <p>
                Praca z dzieckiem w domu nie może być nudna i sztywna. Dziecko
                potrzebuje ćwiczeń zmiennych tzn, mieszanych. Nie możemy wymagać
                od 3 lub 4 latka, aby siedział nieruchomo i z zaangażowaniem
                wykonywał polecenia podczas trwającej 45 minut sesji terapii
                logopedycznej. Percepcja dziecka w tym wieku pozwala na 15-25
                minut pracy. Pamiętajmy, że przy pracy logopedycznej są to
                częstozajęcia 1 na 1. Dlatego ważnym jest, aby zmieniać
                ćwiczenia, a jeżeli wymaga tego sytuacja zrobić krótką przerwę.
              </p>
              <BlogSubtitle>
                Podczas pracy z dzieckiem wykorzystujemy jego mocne strony i
                rozwijamy słabe
              </BlogSubtitle>
              <p>
                Każdy logopeda powinien znać dziecko z którym współpracuje. Taki
                wywiad powinien być zrobiony z opiekunami prawnymi dziecka. Nie
                tylko diagnoza jest ważna, ale również informacje na temat
                samego dziecka. Ważnym jest posiadać informację np. jak dziecko
                radzi sobie z porażką, jak pracuje w oparciu o motywację itp.
                Mocne i słabe strony są kluczowe w dobrze materiału do ćwiczeń.
              </p>
              <p>
                W aplikacji logopedycznej Kokolingo możemy wybrać i dostosować
                poziom trudności materiału, a dziecko zawsze dostaje pozytywną
                informację zwrotną – to zapewnia duży poziom motywacji i chęćdo
                regularnej pracy!
              </p>
              <BlogSubtitle>Nie wykonujemy zadania za dziecko</BlogSubtitle>
              <p>
                Zamiast tego pokazujemy, mówimy, jak coś zrobić lub wykonujemy
                zadanie wspólnie – w ten sposób dziecko uczy się
                odpowiedzialności za wykonywaną pracę. I jest z siebie bardzo
                dumne! Pozwólmy na samodzielność!
              </p>
              <BlogSubtitle>Uczymy dziecko dbałości o jakość</BlogSubtitle>
              <p>
                Jesteśmy przy dziecku, gdy wykonuje zadanie. Przypominamy
                dziecku, że nie chodzi o szybkość wykonania zadania, a
                dokładność. Lepiej jest wykonać mniejszą ilość zadań niż większą
                w niedbały sposób.
              </p>
              <p>
                W terapii wad wymowy (dyslalii) ważna jest zarówno regularność
                wykonywania zalecanych ćwiczeń logopedycznych, ale też, a nawet
                przede wszystkim precyzja, jakość wykonywania ćwiczeń, czy to
                podczas ćwiczeń oddechowych, ćwiczeń artykulacyjnych, ale też na
                materiale sylabowym i słownym.
              </p>
              <BlogSubtitle>Nie przepytujemy, lecz pytamy</BlogSubtitle>
              <p>
                Dzięki temu dowiadujemy się, jak dziecko rozumie dane
                zagadnienie. Kluczem do zrozumienia i porozumienia z dzieckiem
                jest bycie prawdziwym i autentycznym w swych zamiarach. Dziecko
                musi mieć poczucie bycia wysłuchanym! Dlatego bardzo ważnym jest
                dopytywanie dziecka jak rozumie zadanie, z czym miało problem,
                co było łatwe. Sama obserwacja nie wystarcza. Rozmowa z
                dzieckiem jest dopełnieniem pracy i wyznaczeniem wektorów
                dalszej pracy.
              </p>
              <BlogImage
                imgSrc={projektBezTytulu3}
                imgAlt="Predskolsko dijete i logopedia 3"
                imgTitle="Predskolsko dijete i logopedia 3"
                className="w-25"
              />
              <BlogSubtitle>
                Poświęcamy dziecku tyle czasu, ile potrzebuje
              </BlogSubtitle>
              <p>
                Pracując z dzieckiem nigdy nie powinniśmy się spieszyć. Musimy
                zagwarantować tyle czasu, ile dziecko potrzebuje. Ważnym jest,
                aby podczas pracy nie było bodźców, które będą dekoncentrowały
                dziecko. Jeżeli pracujemy z dzieckiem w jego pokoju, a jest tam
                przedmiot, który sprawia, że dziecko nie może się skupić to po
                prostu pozbądźmy się go. Pamiętajmy, aby starać się doprowadzić
                dziecko do normalnego tempa pracy, odpowiedniego do jego wieku,
                o ile nie ma innych przesłanek, które to uniemożliwiają
              </p>
              <BlogSubtitle>
                Dajemy dziecku odczuć,że w nie wierzymy / Chwalimy za podjęte
                próby
              </BlogSubtitle>
              <p>
                Należy sobie uzmysłowić,że jest wiele dzieci, które lepiej
                pracują, gdy są motywowane. Powinniśmy zawsze dostrzegać chęć
                podjęcia wykonania zadania i następnie samą pracę. Mimo, że
                dziecko wykona niepoprawnie zadania, powinno być wzmocnione
                poprzez pozytywny komentarz logopedy lub rodzica/opiekuna.
                Należy zawsze omówić z dzieckiem wykonane ćwiczenia. Podczas
                tego zabiegu wskazujemy jego silne obszary i wyznaczamy ścieżkę
                rozwoju.
              </p>
              <p>
                Terapia logopedyczna to wielkie wyzwanie, sprawmy, aby przez
                stałą motywację, była dla dziecka świetną zabawą, która wspiera
                poczucie własnej wartości, uczy wytrwałości i wspiera rozwój!
              </p>
              <BlogSubtitle>Robimy przerwy</BlogSubtitle>
              <p>
                Przerwy są bardzo istotne w pracy z dzieckiem. Dziecko
                potrzebuje ich ze względu na ograniczone możliwości percepcji. Z
                drugiej strony przerwa jest istotna ze względu na potrzeby
                fizjologiczne człowieka. Pamiętajmy, aby dokonywać przerwy po
                zakończonym ćwiczeniu, a nie w trakcie. Dziecko wykonywujące
                zadanie jest w momencie najwyższej koncentracji i nie należy
                tego procesu przerywać.
              </p>
              <p>
                Czas spędzony z dzieckiem jest niezwykle ważny.Dzięki stałemu
                monitorowaniu postępów wiemy, z czym dziecko sobie radzi, a nad
                czym należy jeszcze popracować. Możemy podnieść jego samoocenę,
                nadrobić zaległości, ugruntować osiągnięte umiejętności. Stała
                praca z dzieckiem oparta o stałe dni i godziny współpracy z
                dzieckiem pomoże wypracować nawyk regularnego uczenia się.
                Pamiętajmy, iż dziecko w wieku przedszkolnym lub wczesnoszkolnym
                nie ma motywacji wewnętrznej. Dziecko ćwiczy, podejmuje zadania,
                głównie z dwóch powodów tj,: zajęcia są atrakcyjne lub
                nauczyciel/logopeda jest lubiany przez dziecko. Przedszkolak nie
                jest świadomy, że poprawna wymowa jest dla niego na tyle ważna,
                że widzi już teraz sens jej poprawy. Należy dziecku stopniowo
                ukazywać sens i zastosowanie zdobytej wiedzy w życiu codziennym.
                Należy pamiętać,że na tym etapie kluczową kwestią jest liczba
                zajęć, a nie ich długość. Lepsze rezultaty przyniosą dwa lub
                trzy krótkie ćwiczenia w ciągu dnia niż godzina poświęcona raz w
                tygodniu.
              </p>
              <p>To co? Ruszamy ku logopedycznej przygodzie?:)</p>
              <Link
                href="https://www.kokolingo.pl"
                title="Przedszkolak I Logopedia"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.kokolingo.pl
              </Link>
              <div>
                <BlogCredits author="Michał Drab, Katarzyna Łuszczak" />
              </div>
            </div>
            <BlogControls
              nextPage={routes.SPEECH_DISORDER}
              prevPage={routes.DISTANCE_SPEECH_THERAPY}
            />
          </div>
          <BlogArticles />
        </div>
        <BackToTopButton />
      </div>
    </Page>
  );
};

export default PrzedszkolakILogopedia;
